import React from 'react';
import Paragraph from '@components/paragraph';
import ViewContainer from '@components/viewContainer';

const Club = (): React.ReactElement => {
    return (
        <ViewContainer>
            <h1 className="title is-4">Kenshikai Karate-Do</h1>
            <Paragraph>
                Hokama’s Kenshikai karate and kobudo were brought from Okinawa to Finland by Kimmo
                Viitaharju (Kenshikai Karate-Do) and Mikki Korhonen (Kenshikai Finland) in 2014.
                Both above mentioned branches represent Hokama’s Kenshikai headquarters in Okinawa.
            </Paragraph>
            <Paragraph>
                In Kenshikai Karate-Do we like to train hard and in relaxed atmosphere. Most of the
                club’s training concentrate on karate while kobudo being practised more as a side
                training. In karate training a lot of emphasis is given to bunkai practise. In other
                words kata applications and different sorts of sparring excercises form the core of
                our club trainings.
            </Paragraph>
            <Paragraph>
                In our training the function is emphasized over form. Thus it is pretty easy to come
                on board if you have experience from any combat ”sports”.
            </Paragraph>
            <Paragraph>
                In case you are interested to join our club please be in contact and let’s arrange
                you a chance to come and try how our training feels to you.
            </Paragraph>
        </ViewContainer>
    );
};

export default Club;
